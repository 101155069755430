import React from "react";

import VideoPlayer from "../video/video.component";

import { Container } from "./short-promotion-video.styles";

const ShortPromotionVideo = () => {
	return (
		<Container>
			<VideoPlayer
				//16: 9 video format
				srcVideo={
					"https://player.vimeo.com/external/845411098.m3u8?s=9b2e6d5692baa04c7ea1f8bb0adf9a4dbe34cdfa"
				}
				autoPlay={true}
				aspectRatio={[9, 16]}
				controls={false}
				transitionDuration={0}
				muted={true}
				loop={true}
			></VideoPlayer>
		</Container>
	);
};

export default ShortPromotionVideo;
