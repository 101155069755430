export const offers = [
	{
		Title: "VIP",
		PromotionText: [
			"30 minutes long live lessons every week",
			"zoom conversation lessons every week",
		],
		// VideoHLSurl:
		// 	"https://player.vimeo.com/external/769430853.m3u8?s=f7f6f4553dc03fc78585553b809d132fe49f5cb5",
	},
	{
		Title: "BASIC",
		PromotionText: [
			"short video lessons for beginners every week.",
			"audio recordings",
			"flashcards",
		],
	},
];
