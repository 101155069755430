import styled from "styled-components";

import { opaqueLightGray } from "../../constants/colours";

import { breakPoints } from "../../utils/css.utils";

export const OfferContainer = styled.div`
	display: flex;
	background-color: ${opaqueLightGray};
	-webkit-backdrop-filter: blur(3px);
	backdrop-filter: blur(3px);
	border-radius: 40px;
	align-items: center;
	flex-direction: column;
	padding: 20px;
	/* margin: 0 10px 10px 10px; */
	margin: 5px;
	transition-duration: 0.2s;

	@media only screen and (min-width: ${breakPoints.subscriptionPage}) {
		&:hover {
			padding: 25px 30px 25px 30px;
			margin: 10px 0px 0px 0px;
		}
		margin: 10px;
	}
`;

export const ButtonContainer = styled.div`
	height: 50px;
	width: 100%;
	padding-top: 40px;
	overflow: hidden;
	position: relative;
	${props => props.styles}
	min-width: 200px;
`;

export const Information = styled.p`
	content: "blah";
	position: absolute;
	top: 20px;
	left: 2px;
	margin: 0px;
	overflow: unset;
`;

export const OfferTitle = styled.p`
	font-size: 70px;
	color: #fdbf00;
	margin: 0;
	-webkit-text-stroke: 1px black;
	@media only screen and (max-width: ${breakPoints.subscriptionPage}) {
		font-size: 60px;
	}
`;

export const Divider = styled.div`
	background-color: gray;
	height: 1px;
	width: 100%;
`;

export const Benefits = styled.p`
	margin: 5px;
	color: gray;
`;

export const BenefitsTitle = styled.p`
	font-size: 20px;
	margin: 5px;
`;
